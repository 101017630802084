.AnswerComponent {

  .answer {
    border-radius: 25px;
    background: rgba(0, 0, 0, .65) url("https://media.istockphoto.com/id/1133497899/photo/old-dirty-paper-texture.jpg?s=612x612&w=0&k=20&c=0BnHDfayvJAmR6CGK2JUrgOh2R_C1RU7XZqUVyloHNc=");
    padding: 10px;
    width: 800px;
    height: auto;
    color: black;
    margin: 6px auto;
  }

  .answer:hover {
    background: #e1e1e1;
    border-top: 1px solid #d0d0d0;
  }

  .active {
    font-weight: bold;
    color: deepskyblue;
  }

  .correct{
    font-weight: bold;
    color: green;
  }

}
