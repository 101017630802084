.ValuePickerComponent {

  .buttonValue {
    background-color: #72d3fe;
    border-color: #72d3fe;

  }
  .buttonValue:hover {
    background-color:  white!important;
    border-color: white!important;
    color: #72d3fe!important;
    font-weight: bold;

  }
  .active {
    background-color:  white !important;
    border-color:  white!important;
    color: #72d3fe!important;
    font-weight: bold;
  }

}
