.ResultComponent {

  .correct {
    padding-left: 150px;
    font-size: 20px;
  }

  .procent {
    font-size: 20px;
  }

  .result {
    padding: 30px;
    font-size: 26px;
    border-radius: 25px;
    width: 600px;
    height: auto;
    color: white;
    font-weight: bold;
    margin: 100px auto auto;
    text-align: center;
  }

  .plus {
    background-color: darkolivegreen;
  }

  .minus {
    background-color: indianred;
  }
}
