.QuestionComponent {

  .explanation {
    border-radius: 25px;
    background: rgba(0, 0, 0, .65) url("https://media.istockphoto.com/id/1133497899/photo/old-dirty-paper-texture.jpg?s=612x612&w=0&k=20&c=0BnHDfayvJAmR6CGK2JUrgOh2R_C1RU7XZqUVyloHNc=");
    padding: 20px;
    width: 800px;
    height: auto;
    color: black;
    margin: 100px auto auto;
  }

  .button-next {
    position: absolute;
    right: 200px;
    bottom: 100px;
    font-weight: bold;
    background-color: #72d3fe!important;
    border-color: #72d3fe!important;
  }

}
