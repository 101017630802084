.SetupPageComponent {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  .setupPage {
    margin: 200px auto auto;
  }

  .buttonCategory {
    margin: 6px;
    font-weight: bold;
    color: #72d3fe;
    border: #72d3fe;
  }

  .buttonCategory:hover {
    color: #ebebeb;
    background-color: #72d3fe;
  }

  .buttonCategory.active {
    color: #ebebeb;
    background-color: #72d3fe;
  }

  .questionsValue {
    margin-bottom: 6px;
  }

  .buttonNext:disabled {
    background-color: transparent;
    color: #72d3fe;
  }

  .buttonNext {
    color: #ebebeb;
    background-color: #72d3fe;
    border: #72d3fe;
    font-weight: bold;
    width: 80px;
    height: 40px;
    position: absolute;
    right: 200px;
    bottom: 100px;
  }

  .buttonNext:hover {
    color: #ebebeb !important;
    background-color: #72d3fe !important;
  }

  .advancementName {
    display: inline-block;
    width: 100px;
  }

}
